var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "div",
        [
          _vm.errorMessage
            ? _c("CAlert", { attrs: { color: "warning" } }, [
                _vm._v("Fehler: " + _vm._s(_vm.errorMessage))
              ])
            : _vm._e(),
          _c(
            "CCard",
            [
              _c(
                "CCardBody",
                [
                  _c("h1", [
                    _vm._v(
                      _vm._s(_vm.moduleSubscription.module) +
                        ": " +
                        _vm._s(_vm.moduleSubscription.company)
                    )
                  ]),
                  _c("ValidatedInput", {
                    attrs: {
                      type: "date",
                      translationKey: "moduleSubscription.startedAt",
                      field: _vm.$v.moduleSubscription.startedAt,
                      value: _vm.$v.moduleSubscription.startedAt.$model
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(
                          _vm.$v.moduleSubscription.startedAt,
                          "$model",
                          $event
                        )
                      }
                    }
                  }),
                  _c("ValidatedInput", {
                    attrs: {
                      type: "date",
                      translationKey: "moduleSubscription.endedAt",
                      field: _vm.$v.moduleSubscription.endedAt,
                      value: _vm.$v.moduleSubscription.endedAt.$model
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(
                          _vm.$v.moduleSubscription.endedAt,
                          "$model",
                          $event
                        )
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "CCardFooter",
                [
                  _c("CButton", {
                    directives: [
                      {
                        name: "t",
                        rawName: "v-t",
                        value: "forms.common.saveAndClose",
                        expression: "'forms.common.saveAndClose'"
                      }
                    ],
                    attrs: { color: "primary" },
                    on: { click: _vm.handleSubmit }
                  }),
                  _c("CButton", {
                    directives: [
                      {
                        name: "t",
                        rawName: "v-t",
                        value: "forms.common.cancel",
                        expression: "'forms.common.cancel'"
                      }
                    ],
                    attrs: { color: "secondary" },
                    on: { click: _vm.handleCancel }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }